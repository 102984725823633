export const fetchTotalApps = async (platform) => {
  try {
    const response = await fetch(
      `https://api.marketplacediff.com/api/${platform}/totalApps`
    );
    const data = await response.json();
    return data.count;
  } catch (err) {
    console.error("ERROR: " + err);
  }
};

export const fetchNewAppsSinceLastWeek = async (platform) => {
  const response = await fetch(
    `https://api.marketplacediff.com/api/${platform}/newAppsLastWeek`
  );
  const data = await response.json();
  return data.count;
};

export const fetchRankChanges = async (platform) => {
  return 0;
  // const response = await fetch(
  //   `https://api.marketplacediff.com/api/${platform}/rankChanges`
  // );
  // const data = await response.json();
  // return data.count;
};

// Add similar functions for other metrics if needed
