export const subscribeUser = async (email, expectation) => {
  const response = await fetch(
    "https://api.marketplacediff.com/api/subscribe",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, expectation }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to subscribe user");
  }

  return response.json();
};
