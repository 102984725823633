// function DiffColumnCard({ app }) {
//   return (
//     <div className="diff-app-card">
//       <a href={app.link} title={app.description}>
//         {app.name}
//       </a>
//       {/* You can expand this component to include rank, categories, etc. in the future */}
//     </div>
//   );
// }

// export default DiffColumnCard;

function DiffColumnCard({ app }) {
  return (
    <div className="diff-app-card">
      <a href={app.link} title={app.description}>
        <div className="app-card-content">
          <img src={app.logo} className="app-icon" />
          <div className="app-details">
            <p className="app-name">{app.name}</p>
            <p className="app-description">{app.description}</p>
            <p className="app-category">{app.category}</p>
          </div>
        </div>
      </a>
      {/* You can expand this component to include rank, categories, etc. in the future */}
    </div>
  );
}

export default DiffColumnCard;
