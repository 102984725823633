import React from "react";
import { ASCII } from "../theme/ASCIIStyles";

function Header() {
  return (
    <div className="header">
      <pre>{ASCII}</pre>
    </div>
  );
}

export default Header;
