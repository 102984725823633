import React from "react";
import DiffColumnCard from "./DiffColumnCard";

function DiffColumn({ title, apps, color }) {
  // Determining gradient based on the color
  let gradient;
  //   if (color === "#611f69") {
  //     // Slack
  //     gradient = "linear-gradient(135deg, #b3a2b3 0%, #611f69 100%)";
  //   } else if (color === "#464775") {
  //     // Teams
  //     gradient = "linear-gradient(135deg, #a5a9bf 0%, #464775 100%)";
  //   } // You can add more conditions for other platforms

  return (
    <div className="marketplace-column" style={{ background: gradient }}>
      <h2>{title}</h2>
     
      <div className="apps-list">
        {apps.map((app) => (
          <DiffColumnCard key={app.name} app={app} />
        ))}
      </div>
    </div>
);

}

export default DiffColumn;

// import DiffColumnCard from "./DiffColumnCard";

// function DiffColumn({ title, apps, color }) {
//   // Determining gradient based on the color
//   let gradient;
//   //   if (color === "#611f69") {
//   //     // Slack
//   //     gradient = "linear-gradient(135deg, #b3a2b3, #611f69)";
//   //   } else if (color === "#464775") {
//   //     // Teams
//   //     gradient = "linear-gradient(#464775,  #a5a9bf, 135deg)";
//   //   } // You can add more conditions for other platforms

//   return (
//     <div className="marketplace-column" style={{ background: gradient }}>
//       <h2>{title}</h2>
//       {apps.map((app) => (
//         <DiffColumnCard key={app.name} app={app} />
//       ))}
//     </div>
//   );
// }

// export default DiffColumn;
