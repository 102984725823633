import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import the hook
import "./NewAppsPage.css";
import { getNewApps } from "../api/fetchApps";

function NewAppsPage() {
  const [apps, setApps] = useState([]);
  const { platform } = useParams(); // Use the hook to get the platform parameter

  useEffect(() => {
    async function fetchData() {
      try {
        const appsList = await getNewApps(platform); // Use the platform variable directly

        setApps(appsList);
      } catch (error) {
        console.error("Error fetching the apps:", error);
      }
    }

    fetchData();
  }, [platform]);

  if (!apps.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="new-apps-list">
      <h1>New Apps for {platform}</h1>
      {apps.map((app) => (
        <a
          href={app.link}
          target="_blank"
          rel="noopener noreferrer"
          key={app.id}
          className="app-item"
        >
          <img src={app.logo} alt={app.name} className="app-logo" />
          <div className="app-details">
            <h2 className="app-name">{app.name}</h2>
            <p className="app-description">{app.description}</p>
          </div>
        </a>
      ))}
    </div>
  );
}

export default NewAppsPage;
