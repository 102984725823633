// import React, { useState, useEffect } from "react";
// import DiffColumn from "./DiffColumn";
// import {
//   fetchSlackAppsNotOnTeams,
//   fetchTeamsAppsNotOnSlack,
// } from "../api/fetchApps";

// function MainContainer() {
//   const [slackApps, setSlackApps] = useState([]);
//   const [teamsApps, setTeamsApps] = useState([]);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const slackData = await fetchSlackAppsNotOnTeams();
//         const teamsData = await fetchTeamsAppsNotOnSlack();

//         setSlackApps(slackData);
//         // console.log("Updated Slack Apps State:", slackApps); /

//         setTeamsApps(teamsData);
//       } catch (error) {
//         console.error("Error fetching the apps:", error);
//       }
//     }

//     fetchData();
//   }, []);

//   return (
//     <div className="main-container">
//       <DiffColumn
//         title="Apps on Slack, not on Teams"
//         apps={slackApps}
//         color="#611f69"
//       />
//       <DiffColumn
//         title="Apps On Teams, not on Slack"
//         apps={teamsApps}
//         color="#464775"
//       />
//       {/* Add more columns for other platforms here */}
//     </div>
//   );
// }

// export default MainContainer;

import React, { useState, useEffect } from "react";
import DiffColumn from "./DiffColumn";
import {
    fetchSlackAppsNotOnTeams,
    fetchTeamsAppsNotOnSlack,
} from "../api/fetchApps";

function MainContainer() {
    const [slackApps, setSlackApps] = useState([]);
    const [teamsApps, setTeamsApps] = useState([]);
  
    useEffect(() => {
      async function fetchData() {
        try {
          const slackData = await fetchSlackAppsNotOnTeams();
          const teamsData = await fetchTeamsAppsNotOnSlack();
  
          setSlackApps(slackData);
          // console.log("Updated Slack Apps State:", slackApps); /

        setTeamsApps(teamsData);
      } catch (error) {
          console.error("Error fetching the apps:", error);
      }
    }

    fetchData();
  }, []);

  return (
      <div className="main-container">
      <DiffColumn
        title="Apps on Slack, not on Teams"
        apps={slackApps}
        color="#611f69"
      />
      <DiffColumn
        title="Apps On Teams, not on Slack"
        apps={teamsApps}
        color="#464775"
      />
      {/* Add more columns for other platforms here */}
    </div>
  );
}

export default MainContainer;