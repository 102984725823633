const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.marketplacediff.com"
    : "http://localhost:3000";

export async function fetchApps(platform, category) {
  let endpoint = "";

  if (platform === "slack") {
    endpoint = `${baseUrl}/slack/${category}`;
  } else if (platform === "teams") {
    endpoint = `${baseUrl}/teams`;
  }

  const response = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
}

export const fetchSlackAppsNotOnTeams = async () => {
  try {
    const response = await fetch(`${baseUrl}/slackButNotTeams`);
    const data = await response.json();
    console.log("DATA: ");
    return data.apps;
  } catch (error) {
    console.error("Error fetching Slack apps:", error);
    throw error;
  }
};

export const fetchTeamsAppsNotOnSlack = async () => {
  try {
    const response = await fetch(`${baseUrl}/teamsButNotSlack`);
    const data = await response.json();
    return data.apps;
  } catch (error) {
    console.error("Error fetching Teams apps:", error);
    throw error;
  }
};

export const getNewApps = async (platform) => {
  try {
    const response = await fetch(`${baseUrl}/api/new-apps/${platform}`);
    const data = await response.json();
    return data.apps;
  } catch (error) {
    console.error("Error fetching Teams apps:", error);
    throw error;
  }
};
