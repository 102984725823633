// import React, { useState, useEffect } from "react";
// import {
//   fetchTotalApps,
//   fetchNewAppsSinceLastWeek,
//   fetchRankChanges,
// } from "../api/fetchMetrics";

// function MetricsBox() {
//   const [slackTotalApps, setSlackTotalApps] = useState(0);
//   const [teamsTotalApps, setTeamsTotalApps] = useState(0);
//   const [slackNewApps, setSlackNewApps] = useState(0);
//   const [teamsNewApps, setTeamsNewApps] = useState(0);
//   const [slackRankChanges, setSlackRankChanges] = useState(0);
//   const [teamsRankChanges, setTeamsRankChanges] = useState(0);

//   useEffect(() => {
//     async function fetchData() {
//       const [
//         slackTotal,
//         teamsTotal,
//         slackNew,
//         teamsNew,
//         slackRanks,
//         teamsRanks,
//       ] = await Promise.all([
//         fetchTotalApps("slack"),
//         fetchTotalApps("teams"),
//         fetchNewAppsSinceLastWeek("slack"),
//         fetchNewAppsSinceLastWeek("teams"),
//         fetchRankChanges("slack"),
//         fetchRankChanges("teams"),
//       ]);

//       setSlackTotalApps(slackTotal);
//       setTeamsTotalApps(teamsTotal);
//       setSlackNewApps(slackNew);
//       setTeamsNewApps(teamsNew);
//       setSlackRankChanges(slackRanks);
//       setTeamsRankChanges(teamsRanks);
//     }

//     fetchData();
//   }, []);

//   return (
//     <div className="metrics-box">
//       <h3>Marketplace Metrics</h3>
//       <div className="metrics-container">
//         <div className="metric-block">
//           <h4>Slack</h4>
//           <p>Total Apps: {slackTotalApps}</p>
//           <p>New Apps (Since Last Week): {slackNewApps}</p>
//           {/* <p>Rank Changes: {slackRankChanges}</p> */}
//         </div>
//         <div className="metric-block">
//           <h4>Teams</h4>
//           <p>Total Apps: {teamsTotalApps}</p>
//           <p>New Apps (Since Last Week): {teamsNewApps}</p>
//           {/* <p>Rank Changes: {teamsRankChanges}</p> */}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MetricsBox;

import React, { useState, useEffect } from "react";
import {
  fetchTotalApps,
  fetchNewAppsSinceLastWeek,
  fetchRankChanges,
} from "../api/fetchMetrics";

function MetricsBox() {
  const [slackTotalApps, setSlackTotalApps] = useState(0);
  const [teamsTotalApps, setTeamsTotalApps] = useState(0);
  const [slackNewApps, setSlackNewApps] = useState(0);
  const [teamsNewApps, setTeamsNewApps] = useState(0);
  const [slackRankChanges, setSlackRankChanges] = useState(0);
  const [teamsRankChanges, setTeamsRankChanges] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const [
        slackTotal,
        teamsTotal,
        slackNew,
        teamsNew,
        slackRanks,
        teamsRanks,
      ] = await Promise.all([
        fetchTotalApps("slack"),
        fetchTotalApps("teams"),
        fetchNewAppsSinceLastWeek("slack"),
        fetchNewAppsSinceLastWeek("teams"),
        fetchRankChanges("slack"),
        fetchRankChanges("teams"),
      ]);

      setSlackTotalApps(slackTotal);
      setTeamsTotalApps(teamsTotal);
      setSlackNewApps(slackNew);
      setTeamsNewApps(teamsNew);
      setSlackRankChanges(slackRanks);
      setTeamsRankChanges(teamsRanks);
    }

    fetchData();
  }, []);

  return (
    <div className="metrics-box">
      <h3>Marketplace Metrics</h3>
      <div className="metrics-container">
        <div className="metric-block">
          <h4>Slack</h4>
          <p>
            Total Apps: {slackTotalApps}
            <br />
            <a href="/new-apps/slack">
              New Apps (Since Last Week): {slackNewApps}
            </a>
          </p>
          {/* <p>Rank Changes: {slackRankChanges}</p> */}
        </div>
        <div className="metric-block">
          <h4>Teams</h4>
          <p>
            Total Apps: {teamsTotalApps}
            <br />
            <a href="/new-apps/teams">
              New Apps (Since Last Week): {teamsNewApps}
            </a>
          </p>
          {/* <p>Rank Changes: {teamsRankChanges}</p> */}
        </div>
      </div>
    </div>
  );
}

export default MetricsBox;
