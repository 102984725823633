// import React, { useState } from "react";
// import { subscribeUser } from "./../api/updateData";
// function Landing() {
//   const [email, setEmail] = useState("");
//   const [expectation, setExpectation] = useState("");
//   const [submitted, setSubmitted] = useState(false);
//   const [emailError, setEmailError] = useState("");
//   const [expectationError, setExpectationError] = useState("");

//   const upcomingApps = [
//     "Discord",
//     "Chrome Extensions",
//     "Firefox Add-ons",
//     "VS Code Plugins",
//     "Zoom Plugins",
//   ];

//   const validateEmail = (email) => {
//     const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
//     return regex.test(email);
//   };

//   const validateTextbox = (text) => {
//     if (text.length > 500) {
//       // limit to 500 characters
//       return false;
//     }
//     return true;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!validateEmail(email)) {
//       setEmailError("Please enter a valid email address.");
//       return;
//     }

//     if (!validateTextbox(expectation)) {
//       setExpectationError(
//         "Your input is too long! Please keep it under 500 characters"
//       );
//     }

//     setEmailError(""); // clear previous error messages

//     try {
//       await subscribeUser(email, expectation);
//       setSubmitted(true);
//     } catch (error) {
//       console.error("Error while subscribing:", error);
//     }

//     setEmail("");
//     setExpectation("");
//   };

//   return (
//     <div className="landing">
//       <p className="intro-text">
//         Welcome to MarketPlaceDiff. Currently, in beta, we're working hard to
//         offer you comprehensive insights into various digital marketplaces. Be
//         the first to know when we go live!
//       </p>

//       {submitted ? (
//         <p className="thank-you-message">
//           Thanks for your interest! 🎉 We'll keep you in the loop. Stay tuned!
//         </p>
//       ) : (
//         <form onSubmit={handleSubmit} className="subscription-form">
//           {""}

//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           {emailError && <p className="error-message">{emailError}</p>}
//           <textarea
//             placeholder="What are you looking forward to?"
//             value={expectation}
//             onChange={(e) => setExpectation(e.target.value)}
//           />
//           <button type="submit">Keep Me Updated</button>
//         </form>
//       )}

//       <div className="upcoming-apps">
//         <h3>Upcoming Marketplaces:</h3>
//         <div className="app-cards">
//           {upcomingApps.map((app) => (
//             <div className="app-card" key={app}>
//               {app}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Landing;

import React, { useState } from "react";
import { subscribeUser } from "./../api/updateData";
function Landing() {
  const [email, setEmail] = useState("");
  const [expectation, setExpectation] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [expectationError, setExpectationError] = useState("");

  const upcomingApps = [
    "Discord",
    "Chrome Extensions",
    "Firefox Add-ons",
    "VS Code Plugins",
    "Zoom Plugins",
  ];

  const validateEmail = (email) => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return regex.test(email);
  };

  const validateTextbox = (text) => {
    if (text.length > 500) {
      // limit to 500 characters
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!validateTextbox(expectation)) {
      setExpectationError(
        "Your input is too long! Please keep it under 500 characters"
      );
    }

    setEmailError(""); // clear previous error messages

    try {
      await subscribeUser(email, expectation);
      setSubmitted(true);
    } catch (error) {
      console.error("Error while subscribing:", error);
    }

    setEmail("");
    setExpectation("");
  };

  return (
    <div className="landing">
      <p className="intro-text">
        Discover the Uncharted Territories of Digital Marketplaces. Track
        competitors, identify gaps, and optimize your app's presence. the first
        to know when we go live!
      </p>

      {submitted ? (
        <p className="thank-you-message">
          Thanks for your interest! 🎉 We'll keep you in the loop. Stay tuned!
        </p>
      ) : (
        <form onSubmit={handleSubmit} className="subscription-form">
          {""}

          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {emailError && <p className="error-message">{emailError}</p>}
          <textarea
            placeholder="What are you looking forward to?"
            value={expectation}
            onChange={(e) => setExpectation(e.target.value)}
          />
          <button type="submit">Start Discovering</button>
        </form>
      )}

      <div className="upcoming-apps">
        <h3>Upcoming Marketplaces:</h3>
        <div className="app-cards">
          {upcomingApps.map((app) => (
            <div className="app-card" key={app}>
              {app}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Landing;
