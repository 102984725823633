import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import "./App.css";
import Landing from "./components/Landing";
import MainContainer from "./components/Marketplacediffs";
import MetricsBox from "./components/MetricsBox";
import NewAppsPage from "./components/NewAppsPage"; // Assuming it's located here

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <Routes>
          <Route
            path="/"
            element={
              <>
                <MetricsBox />
                <MainContainer />
              </>
            }
          />

          <Route path="/new-apps/:platform" element={<NewAppsPage />} />

          {/* Add other routes as needed */}
        </Routes>
        <Landing />
      </Router>
    </div>
  );
}

export default App;

//  {/* <div className="marketplace-columns">
//         <MarketplaceColumn platform="slack" category="tools" />
//         <MarketplaceColumn platform="teams" category="tools" />
//       </div> */}
